#banner {
  width: 100%;
  padding: auto;
  margin: auto;
  padding-block: 5em;
}

#banner .land {
  display: flex;
  gap: 2em;
  width: 100%;
  justify-content: center;
  align-items: self;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-bottom: 3em;
}
#banner .lab {
  text-align: center;
}
#banner .label1 {
  text-align: center;
  justify-content: center;
  font: normal normal bold 30px/40px Book Antiqua;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
#banner .label1 span {
  text-align: center;
  font: normal normal normal 30px/40px Gigi;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
#banner .bannerimage {
  background: #ffffff padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #707070;
  opacity: 1;
  width: 900px;
}
#banner .cardimage {
  width: 300px;
}
#banner .banner-card {
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: space-around;
  padding-inline: 2em;
  padding-block: 2em;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
#banner .galary{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#banner .card-container {
  columns: 3 30px;
  column-gap: 1em;
  width: 80%;
  margin: 0 auto;
  
}

#banner .card {
    background-color: #ffff;
    border: 0px solid #ccc;
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 1em 1em 0;
    display: inline-block;
    width: 100%;
    padding: 5px;
}

#banner .card .card1 {
  width: 90%;
  margin: auto;
  height: auto;
  padding-top: 1em;
  align-self: center;
  display: block;
  object-fit: cover;
}
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
#banner .card-title label {
  text-align: center;
  font: 30px 'Sacramento';
  letter-spacing: 1px;
  color: #707070;
  opacity: 1;
}

#banner .card-description {
  width: 2em;
}
#banner .disc {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline: 1em;
  margin: 1em;
  gap: 1em;
  flex-wrap: wrap;
  width: 90%;
}

@media (max-aspect-ratio: 1/1), (max-width: 900px) {
  #banner .land{
    padding-bottom: 1em;
  }
  #banner .card-description img{
width: 1.5em;
  }
  #banner .bannerimage {
    background: #ffffff padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #707070;
    opacity: 1;
    width: 320px;
  }
  #banner {
    display: flex;
    gap: 2em;
    justify-content: space-between;
    margin: auto;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-block: 3em;
  }
  #banner .cardimage {
    width: 200px;
  }
  #banner .thumbnail-container {
    flex-direction: column;
  }
  
  #banner .carousel-button {
    top: calc(50% - 20px);
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  #banner .full-screen-image {
    width: 70%;
    height: auto;
  }
  #banner .carousel-button .prev {
    left: 1em;
  }
  
  #banner .carousel-button .next {
    right: 1em;
  }
  
  #banner .exit-full-screen-button {
    top: 10px;
    right: 10px;
  }
  #banner .card-container{
  display: flex;
  flex-direction: column;
  width: 90%;

  }
}

#banner .full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}

#banner .full-screen-image {
  max-width: 90%;
  max-height: 90%;
}

#banner .exit-full-screen-button {
  position: absolute;
  top: 5%;
  right: 20px;
  cursor: pointer;
  z-index: 101;
  filter: invert(100%);
}

#banner .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  z-index: 101;
}

#banner .carousel-button.prev {
  left: 20px;
}

#banner .carousel-button.next {
  right: 20px;
}



