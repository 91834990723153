#adpage{
    width: 100%;
}
.sidebar{
    display:flex;
    padding-inline: 5em;
    background-color: rgb(243, 92, 92);
    align-items: center;
justify-content: space-between;
    color: white;
    font-size: 20px;
}
.menu{
    display:flex ;
    gap: 2em;
}
.btn:hover{
    transform: scale(1.2);
}
.user{
    display: flex;
    align-items: center;
    gap: 1em;
}
.sidebar > div{
    cursor: pointer;
}
.mobSide{
    display:none;
}


@media (max-aspect-ratio: 1/1), (max-width: 900px)  {
    .deskSide{
        display:none;
    }
    .mobSide{
        display: block;
    }
    .sidebar{
        width: 40%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1em;
        padding-inline: 1em;
        padding-block: 3em;
        position: absolute;
        z-index: 500;
        box-sizing: border-box;

    }
    .menu{
        flex-direction: column;
    }
    .user{
        flex-direction: column;
        gap: 0;
    }
}