#contact{
  margin-block: 10em;
  margin-inline: auto;
  width:40%;
  padding-inline: 4em;
  padding-block: 1em;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 34px;
  opacity: 1
}
#contact .cont{
    padding-block: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2em;
}
#contact .p{
  text-align: center;
  font: normal normal normal 25px/30px ABeeZee;
  letter-spacing: 0px;
  color: #4C4C4C;
    opacity: 1;
}
#contact .icon {
    display: flex;
    gap: 6em;
    filter: invert(100%);
  }
  #contact .icon p {
    font: normal normal normal 25px/30px ABeeZee;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
  #contact .icon .phone {
    display: flex;
    gap: 1em;
  }

  
  #contact input,#contact span,#contact label,#contact textarea ,#contact .question1{
  display: block;
  margin: 10px;
  padding: 5px;
  border: none;
  font-size: 20px;
  font: normal normal normal 15px/18px ABeeZee;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

#contact textarea:focus,#contact input:focus {
  outline: 0;
}
/* Question */

#contact input.question,#contact textarea.question ,#contact .question1{
  font-size: 20px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 80%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

#contact input.question + label,#contact textarea.question + label,#contact .question1 + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0.5em;
  margin: 0;
  width: 10%;
  border-top: 1px solid red;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

#contact input.question:focus + label,#contact textarea.question:focus + label,#contact .question1:focus + label {
  width: 80%;
}

#contact input.question:focus,#contact input.question:valid ,#contact .question1:valid,#contact .question1:focus {
  padding-top: 35px;
}

#contact textarea.question:valid,#contact textarea.question:focus {
  margin-top: 20px;
}

#contact input.question:focus + label > span,#contact input.question:valid + label > span {
  top: -60px;
  font-size: 18px;
  color: #333;
}
#contact .question1:focus + label > span,#contact .question1:valid + label > span {
  top: -60px;
  font-size: 18px;
  color: #333;
}

#contact textarea.question:focus + label > span,#contact textarea.question:valid + label > span {
  top: -80px;
  font-size: 18px;
  color: #000000;
}

#contact input.question:valid + label,#contact textarea.question:valid + label,#contact .question1:valid + label {
  border-color: green;
}

#contact input.question:invalid,#contact textarea.question:invalid,#contact .question1:invalid {
  box-shadow: none;
}

#contact input.question + label > span,#contact textarea.question + label > span ,#contact .question1 + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #8F8F8F;
  font-size: 20px;
  top: -40px;
  left: 0px;
  z-index: 1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}

#contact input[type="submit"] {
  -webkit-transition: opacity 0.2s ease, background 0.2s ease;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: block;
  opacity: 0;
  margin: 10px 0 0 0;
  padding: 10px;
  cursor: pointer;
}

/* #contact input[type="submit"]:hover {
  background: #EEE;
}

#contact input[type="submit"]:active {
  background: #999;
} */

#contact input.question:valid ~ .button,#contact textarea.question:valid ~ .button,#contact .question1:valid ~ .button {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}

#contact input.question:invalid ~ .button,#contact textarea.question:invalid ~ .button,#contact .question1:invalid ~ .button  {
  display: none;
}

#contact .tc{
  text-align: center;
font: italic normal normal 20px/23px ABeeZee;
letter-spacing: 0px;
color: #4C4C4C;
opacity: 1;
}

@-webkit-keyframes appear {
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}
#contact .button{
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

#contact .button:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

@media (max-aspect-ratio: 1/1), (max-width: 900px) {
  #contact{
    width:90%;
    padding-inline: 3em;
  }
  .insta{
    grid-area: insta;
  }
  .whatsapp{
    grid-area: whatsapp;
  }
  .phone{
    grid-area: phone;
  }
  #contact .icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    filter: invert(100%);
    gap:1em;
  }
} 