#detail{
    width: 90%;
    /* background-color: rgb(231, 251, 160); */
    padding: auto;
    margin: auto;
    padding:1em;
    font-size: 20px;
    box-sizing: border-box;
    margin-top: 5em;
    margin-bottom: 5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
#detail .button{
    background-color: #c2fbd7;
    border-radius: 100px;
    box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
    padding: 7px 15px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 14px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    margin-block: 1em;
  }
  
  #detail .button:hover {
    box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
    transform: scale(1.05) rotate(-1deg);
  }
#detail .table{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: .5em;
    margin: auto;
    align-items: center;
}

#detail .body{
    display: flex;
    padding: .5em;
    flex-direction: column;
    justify-content: space-between;
    background-color: antiquewhite;
    gap: .5em;
    margin: auto;
    box-sizing: border-box;
    border-radius: 1em;
    align-items: center;
    margin-top: 1em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-aspect-ratio: 1/1), (max-width: 900px)  {
    
}
