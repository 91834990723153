* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
  background-color:#FFFBE9;
}
