#footer {
  width: 100%;
  padding-block: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: black;
  margin-top: auto;
  /* position: sticky; */
  bottom: 0;
}
#footer .itemf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding-inline: 10em;
}
#footer .phone {
  display: flex;
  gap: 1em;
}
#footer .icon {
  display: flex;
  gap: 2em;
}
#footer p {
  font: normal normal normal 20px/25px ABeeZee;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
#footer .cr{
  color: #ffff;
}
#footer .lo{
  width: 150px;
}
#footer .flogo{
  width: 90%;
}

@media (max-aspect-ratio: 1/1), (max-width: 900px) {
  #footer .itemf{
    flex-direction: column;
    padding-block:1em;
    padding-inline: 0.3em;
    gap: 1em;
  }
  #footer .icon {
    gap: 1em;
  }
  #footer p {
    font: normal normal normal 20px ABeeZee;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}
