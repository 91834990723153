#main-header {
    width: 100%;
    position: sticky;
    top:0;
    z-index: 200;
    background-color:white;
  }
  #main-header .lo{
   width: 175px;
  }
  #main-header .logo{
    width: 90%;
  }
  #main-header .header {
    background-color:#FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-block: 0em; */
    width: 80%;
    margin: auto;
  }
  #main-header p{
    text-align: left;
    font: 15px/17px ABeeZee;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    cursor: pointer;
  }
  @media (max-aspect-ratio: 1/1), (max-width: 900px) {
    #main-header .header {
      gap:1em;
      align-items: self;
    }
  }